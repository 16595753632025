$("#issuer-industry").change(function(){
  let office = $(this).val();
  $.ajax({
    url: "/deals/industry_specifics",
    method: "GET",
    dataType: "json",
    data: { office: office },
    success: function (response) {
      $("#issuer-industry-specific").empty();
      $("#issuer-industry-specific").append("<option value=''>Select industry...</option>");

      for(var i = 0; i < response.length; i++){
        $("#issuer-industry-specific").append('<option value="' + response[i]["title"] + '">' + response[i]["title"] + '</option>');
      }
    }
  });
})
